.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 30px;
  width: 30px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

pre {
  font-size: 11px !important;
  /* width: auto; */
  /* max-width: 90%; */
  /* background: red; */
}

.mantine-Prism-code {
  padding: 1em;
}
.mantine-Prism-lineContent {
  padding-right: 2em;
  width: auto;
}
.mantine-Prism-root {
  max-width: 85%;
  width: auto;
}
/* GENERAL CLASSES */
.mainBodyLight {
  /* background-image: url(./assets/images/wallp.jpg),
    linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)); */
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexRowSB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.oScrollBar::webkitscrollbar {
  width: 10px;
}
.oScrollBar::webkitscrollbar-thumb {
  background-color: darkgray;
  outline: 1px solid slategray;
  border-radius: 50px;
}
.oScrollBar::webkitscrollbar-track {
  box-shadow: inset 0 0 6px slategray;
}
