.wideCard {
  border-width: 3px;
  border-color: bisque;
  border-style: groove;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
}
.marketCard {
  border: 1px solid gray;
  border-radius: 15px;
  box-shadow: 10px 5px 5px black;
  margin: 2em !important;
  height: 200px;
  min-width: 200px;
  max-width: 250px;
  border-radius: 10px;
  padding: 5px;
}
.cardImg {
  width: 100%;
  height: 170px;
  border-radius: 10px;
}
