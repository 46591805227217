/* add css module styles here (optional) */

.test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn:hover {
  /* box-shadow: 0 3px 4px 0 rgba(0,0,0,0.24), 0 3px 4px 0 rgba(0,0,0,0.19); */
}

.full-primary {
  color: #fff;
  background-color: #2e87d3;
  border-color: #2e87d3;
}

.full-primary:hover {
  color: #fff;
  background-color: #2073bd;
  border-color: #2073bd;
}

.full-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.full-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.full-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.full-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.full-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.full-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.full-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.full-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.full-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.full-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.full-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.full-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.full-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.outline-primary {
  color: #fff;
  border-color: #2e87d3;
}

.outline-primary:hover {
  color: #fff;
  background-color: #2e87d3;
  border-color: #2e87d3;
}

.outline-secondary {
  color: #fff;
  border-color: #6c757d;
}

.outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.outline-success {
  color: #fff;
  border-color: #198754;
}

.outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.outline-danger {
  color: #fff;
  border-color: #dc3545;
}

.outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.outline-warning {
  color: #000;
  border-color: #ffc107;
}

.outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.outline-info {
  color: #000;
  border-color: #0dcaf0;
}

.outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.outline-light {
  color: #000;
  border-color: #f8f9fa;
}

.outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.outline-dark {
  color: #fff;
  border-color: #212529;
}

.outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.link {
  font-weight: 400;
  color: #2e87d3;
  text-decoration: underline;
}

/* for button */

.justify-center {
  margin: 0 auto;
  display: grid;
}

.margin0 {
  margin: 0 auto;
}

.pure-material-progress-circular {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  padding: 0.25em;
  width: 3em;
  height: 3em;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: transparent;
  font-size: 16px;
  overflow: hidden;
}

.pure-material-progress-circular::-webkit-progress-bar {
  background-color: transparent;
}

/* Indeterminate */
.pure-material-progress-circular:indeterminate {
  -webkit-mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
  mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
  animation: pure-material-progress-circular 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
}

:-ms-lang(x), .pure-material-progress-circular:indeterminate {
  animation: none;
}

.pure-material-progress-circular:indeterminate::before,
.pure-material-progress-circular:indeterminate::-webkit-progress-value {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.25em;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
}

.pure-material-progress-circular:indeterminate::-moz-progress-bar {
  box-sizing: border-box;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
}

.pure-material-progress-circular:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

@keyframes pure-material-progress-circular {
  0% {
      transform: rotate(0deg);
  }
  12.5% {
      transform: rotate(180deg);
      animation-timing-function: linear;
  }
  25% {
      transform: rotate(630deg);
  }
  37.5% {
      transform: rotate(810deg);
      animation-timing-function: linear;
  }
  50% {
      transform: rotate(1260deg);
  }
  62.5% {
      transform: rotate(1440deg);
      animation-timing-function: linear;
  }
  75% {
      transform: rotate(1890deg);
  }
  87.5% {
      transform: rotate(2070deg);
      animation-timing-function: linear;
  }
  100% {
      transform: rotate(2520deg);
  }
}

@keyframes pure-material-progress-circular-pseudo {
  0% {
      transform: rotate(-30deg);
  }
  29.4% {
      border-left-color: transparent;
  }
  29.41% {
      border-left-color: currentColor;
  }
  64.7% {
      border-bottom-color: transparent;
  }
  64.71% {
      border-bottom-color: currentColor;
  }
  100% {
      border-left-color: currentColor;
      border-bottom-color: currentColor;
      transform: rotate(225deg);
  }
}



/* for widget */

.monetbil-iframe-container,
.monetbil-payment-widget {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: block;
	background: rgba(0, 0, 0, 0.0065);
	border: 0px none transparent;
	overflow-x: hidden;
	overflow-y: auto;
	visibility: visible;
	margin: 0px;
	padding: 0px;
	z-index: 2147483647
}

.monetbil-iframe-container {
	background: rgba(0, 0, 0, 0.5)
}

.monetbil-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2147483647
}

.monetbil-loading-bar {
	display: inline-block;
	width: 4px;
	height: 18px;
	border-radius: 4px;
	margin-right: 3px;
	animation: monetbil-loading 1s ease-in-out infinite
}

.monetbil-loading-bar:nth-child(1) {
	background-color: #ffffff;
	animation-delay: 0
}

.monetbil-loading-bar:nth-child(2) {
	background-color: #ffffff;
	animation-delay: 0.09s
}

.monetbil-loading-bar:nth-child(3) {
	background-color: #ffffff;
	animation-delay: .18s
}

.monetbil-loading-bar:nth-child(4) {
	background-color: #ffffff;
	animation-delay: .27s
}

@keyframes monetbil-loading {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1, 2.2);
	}
	40% {
		transform: scale(1);
	}
}
