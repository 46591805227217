.sendBtn:hover {
  background-color: indigo;
  cursor: pointer;
}
.messageInput:active {
  border-color: indigo;
  resize: vertical;
}
.mantine-Input-input {
  border-color: gray !important;
  resize: vertical;
  height: auto;
  width: 100%;
}
.mantine-Input-input::spelling-error {
  color: red !important;
  resize: vertical;
}
.mantine-Input-input:focus {
  border-color: indigo !important;
  resize: vertical;
}
.sendBtn:hover {
  background-color: indigo !important;
  color: white !important;
}
