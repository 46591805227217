.oScrollBar::webkit-scrollbar {
  width: 5px;
}
.oScrollBar::webkit-scrollbar-thumb {
  background-color: darkgray;
  outline: 1px solid slategray;
  border-radius: 50px;
  width: 3px;
}
.oScrollBar::webkit-scrollbar-track {
  box-shadow: inset 0 0 6px slategray;
}
