body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.customScroll::-webkit-scrollbar {
  width: 5px !important; /* width of the entire scrollbar */
  height: 12px !important;
}
.customScroll::-webkit-scrollbar-track {
  background: inherit !important; /* color of the tracking area */
  border: 0.4px dotted gray;
  border-radius: 15px;
  padding: 2px;
}
.customScroll::-webkit-scrollbar-thumb {
  background-color: #8b41fc !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid black !important; /* creates padding around scroll thumb */
}
